exports.components = {
  "component---src-pages-404-ar-js": () => import("./../../../src/pages/404.ar.js" /* webpackChunkName: "component---src-pages-404-ar-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ar-js": () => import("./../../../src/pages/about.ar.js" /* webpackChunkName: "component---src-pages-about-ar-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-approach-ar-js": () => import("./../../../src/pages/approach.ar.js" /* webpackChunkName: "component---src-pages-approach-ar-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-book-tour-js": () => import("./../../../src/pages/book-tour.js" /* webpackChunkName: "component---src-pages-book-tour-js" */),
  "component---src-pages-index-ar-js": () => import("./../../../src/pages/index.ar.js" /* webpackChunkName: "component---src-pages-index-ar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

